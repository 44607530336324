import './App.css';
import React from 'react';
import socketIOClient from "socket.io-client";
import Box from './Box';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      boxes: []
    };
  }

  componentDidMount() {
    this.listenToSocket();
  }

  listenToSocket() {
    if(this.socket) return; // prevent multiple connections
    // this.socket = socketIOClient();

    this.socket = socketIOClient('/', {
      query: {
        visualizer: 'true'
      }
    });

    // some debugging statements concerning socket.io
    this.socket.on('reconnecting', seconds => {
      console.log('reconnecting in ' + seconds + ' seconds');
    });

    this.socket.on('reconnect', () => {
      console.log('reconnected');
    });

    this.socket.on('reconnect_failed', () => {
      console.log('failed to reconnect');
    });

    this.socket.on('connect', () => {
      console.log('socket connected');
    });

    this.socket.on('boxConnected', boxNr => {
      console.log(`> Box ${boxNr} connected.`);
      var box = this.state.boxes.find(box => box.nr == boxNr);
      if(!box) {
        this.state.boxes.push({
          nr: boxNr,
          state: 'connected',
          reads: []
        });
      }else{
        box.state = 'connected';
      }
      this.setState({boxes: this.state.boxes});
    });

    this.socket.on('boxDisconnected', boxNr => {
      console.log(`> Box ${boxNr} disconnected.`);
      var box = this.state.boxes.find(box => box.nr == boxNr);
      if(!box) {
        this.state.boxes.push({
          nr: boxNr,
          state: 'disconnected',
          reads: []
        });
      }else{
        box.state = 'disconnected';
      }

      this.setState({boxes: this.state.boxes});
    });

    this.socket.on('read', read => {
      console.log(`> Read:`, read);

      var box = this.state.boxes.find(box => box.nr == read.box_nr);
      if(!box) {
        // happens when you refresh and we keep no state on the server about the boxes
        this.state.boxes.push({
          nr: read.box_nr,
          state: 'connected',
          reads: [read]
        });
      }else{
        box.reads.push(read);
      }

      this.setState({boxes: this.state.boxes});
    });

    this.socket.on('connectedBoxes', connectedBoxes => {
      console.log(`> connectedBoxes:`, connectedBoxes);

      for(var boxNr of connectedBoxes) {
        console.log(`> Box ${boxNr} connected.`);
        var box = this.state.boxes.find(box => box.nr == boxNr);
        if(!box) {
          this.state.boxes.push({
            nr: boxNr,
            state: 'connected',
            reads: []
          });
        }else{
          box.state = 'connected';
        }
        this.setState({boxes: this.state.boxes});
      }
    });
  }

  render() {
    var renderedBoxes = this.state.boxes.map(box => {
      return (
        <Box key={box.nr} box={box} />
      );
    });

    return(
      <div className="App">
        <div className="boxes">
          {renderedBoxes}
        </div>
      </div>
    );
  }
}

export default App
