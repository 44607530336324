import './Box.css';
import moment from 'moment'
import React from 'react';
import Tag from './Tag';

class Box extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    var reads = this.props.box.reads.sort((a, b) => b.time - a.time);

    var renderedreads = reads.map(read => {
      return (
        <Tag key={read.tag+read.time} read={read} />
      );
    });



    return(
      <div className={`Box ${this.props.box.state}`}>
        <div className="title">&#9679; Box {this.props.box.nr} ({this.props.box.reads.length} reads)</div>
        <div className="reads">{renderedreads}</div>
      </div>
    );
  }


}

export default Box
